/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'circe';
    src: url('/assets/fonts/Circe/circe-bold-webfont.woff2') format('woff2'),
         url('/assets/fonts/Circe/circe-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'circe';
    src: url('/assets/fonts/Circe/circe-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/Circe/circe-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat/Montserrat-Bold.eot');
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('/assets/fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
    url('/assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('/assets/fonts/Jost/jost-v13-cyrillic-500.woff2') format('woff2'),
         url('/assets/fonts/Jost/jost-v13-cyrillic-500.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Jost';
    src: url('/assets/fonts/Jost/jost-v13-cyrillic-regular.woff2') format('woff2'),
         url('/assets/fonts/Jost/jost-v13-cyrillic-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Abel';
    src: local('Abel'),
    url('/assets/fonts/Abel/Abel-ru.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

html, body { height: 100%; }
body {
    background-color: #E5E5E5;
    margin: 0;
    font-family: Circe;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.cke {
    width: 578px !important;
}
h1 {
    color: #3A3F6C;
}
input, textarea, option, select {
    font-family: 'Circe';
}